.PeerViewInfo {
  position: absolute;
  top: 0px;
  z-index: 110;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1rem;
  width: 50%;
  height: 100%;

  p {
    margin: 0;
    color: #ffffff;
  }

  h4 {
    margin: 1em 0 0 0;
    font-size: 1.2em;
    color: #ffffff;
  }

  .changeParam {
    color: rgb(178, 178, 255);
    cursor: pointer;
    display: inline-block;
    margin: 0 0 0 0.5rem;

    &.disabled {
      color: grey;
      cursor: not-allowed;
    }
  }
}
