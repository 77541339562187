@import './common';
@import '../../../assets/scss/core/variables';

$message-border: #aaaaaa;
.msg-highlighted {
  border: 1px solid $secondaryColor !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-out;
}

.deleted_message_content {
  display: inline-flex;
  align-items: center;
  em {
    margin-left: 0.25em;
  }
}

.chat_message-item--self {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .chat_message_footer {
    padding: 0 0.5em 0em !important;
  }
}

.chat_message-item--other {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chat_message-item-sizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%;
}

.chat_message-item-sizer--citation {
  max-width: 100%;
}

.chat_messages {
  flex-basis: 1px;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  padding: 1em 13em;
  position: relative;
  .citation-message {
    background-color: $extraLightgrey;
    padding: 1rem;
    border-radius: 0.5em;
  }

  .chat_message_new_separator {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    margin-bottom: 1.1em;
    margin-top: 1.1em;

    .new_separator_text {
      color: $secondaryColor;
    }

    .new_separator_line {
      margin-left: 0.5em;
      flex-grow: 1;
      flex-shrink: 1;
      border-bottom: 1px solid $secondaryColor;
    }
  }

  .chat_message_date_separator {
    position: sticky;
    top: 1em;
    z-index: 1;
    pointer-events: none;
    &_text {
      width: 10em; //because of sticky, think of removing previous sticky date.
      text-align: center;
      background-color: #ffffff;
      border-radius: 1em;
      font-size: 0.9em;
      align-self: center;
      padding: 0.1em 0.5em;
      color: black;
      border: solid 1px #c5c5c5;
    }
    display: flex;
    align-items: center;
    margin-bottom: 1.1em;
    margin-top: 1.1em;
    .separator_line {
      flex-grow: 1;
      flex-shrink: 1;
      border-bottom: none; //1px solid #c5c5c5;
    }
  }

  .chat_message-container {
    display: flex;
    word-break: break-word;

    &--self {
      justify-content: end;
    }

    .chat_message {
      background-color: #e9e9eb;
      position: relative;
      border-radius: 0.3em;
      border: none;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      width: fit-content;

      .chat_message--citation {
        .chat_message_footer {
          display: none;
        }
      }

      &:hover {
        background-color: $grey;
        .chat_actions_hover {
          &--global {
            display: flex;
            cursor: pointer;
          }
        }

        .chat_message--citation {
          background-color: rgba(0, 0, 0, 0.05);
          cursor: pointer;
        }
      }

      .chat_message_author_icon {
        @extend .chat_icon;
        margin-left: -2.475em;
        position: absolute;
      }

      &--self {
        background-color: #dbe7f2;
        margin-right: 0;
        border: none;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        justify-content: end;

        &:hover {
          background-color: #eef3f9;
          .chat_actions_hover {
            &--global {
              display: flex;
              cursor: pointer;
            }
          }
        }
      }

      &--citation {
        border: none;
        background-color: rgba(0, 0, 0, 0.06);
        margin-left: 0;
        margin-right: 0;
      }

      &--self-edit {
        border: 1px solid;
        border-color: $primaryColorDarker;
      }

      .chat_message_header {
        display: flex;
        border-bottom: 0.1em;
        padding: 0.5em 0.5em 0em 0.5em;
        align-items: baseline;

        .chat_message_author {
          font-size: 0.9em;
          color: #353535;
          font-weight: 600;
          line-height: 1em;

          &--clickable {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .chat_message_footer {
        margin-left: auto;
        display: flex;
        align-items: baseline;
        margin-bottom: 0.5em;
        padding: 0 0.5em;

        .check-icon {
          cursor: pointer;

          svg {
            vertical-align: bottom;
          }

          path {
            vertical-align: bottom;
            transform: translateY(3%);
          }

          .not-seen {
            color: $defaultTextColor;
            margin-left: 0.063rem;

            path {
              transform: translateY(6%);
            }
          }

          .seen {
            color: $secondaryColor;
            margin-left: 0.125rem;
          }
        }

        .chat_message_date {
          font-size: 0.8em;
          color: $defaultTextColor;
          margin-left: auto;
        }
      }

      .chat_message_content_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }

      .chat_message_content_wrapper--citation {
        display: block;
      }

      .chat_message_citation_wrapper {
        margin-bottom: 0.5em;
      }

      .chat_message-body {
        flex: 1 1 auto;
        padding: 0.25em 0.5em 0.5em;
        min-width: 0;
        overflow-x: hidden;
      }

      .chat_message_text {
        font-size: 1em;
        line-height: 1.4em;
        color: #353535;
        overflow-wrap: break-word;

        p,
        ol,
        ul {
          margin: 0;
        }
      }

      .chat_actions_hover {
        display: none;
        // border: 1px solid $message-border;
        border-radius: 0.5em;
        background-color: white;
        box-shadow:
          rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

        &--image {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
        }

        &--global {
          position: absolute;
          top: -1rem;
          right: 0.5rem;
        }

        .chat_action {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.35rem;
          border-radius: 0.4rem;
          color: $defaultTextColor;

          &--border {
            &-left {
              &:hover {
                border-top-left-radius: 0.35em;
                border-bottom-left-radius: 0.35em;
              }
            }
            &-right {
              &:hover {
                border-top-right-radius: 0.35em;
                border-bottom-right-radius: 0.35em;
              }
            }
          }

          &:hover {
            background-color: $lightgrey;
          }
        }

        .chat_action_reply_only {
          border-radius: 0.35em;
        }

        .chat_action_reply_own {
          border-top-left-radius: 0.35em;
          border-bottom-left-radius: 0.35em;
        }

        .chat_action_delete {
          &:hover {
            background-color: $errorColorLighten;
            color: $errorColor;
            border-top-right-radius: 0.35em;
            border-bottom-right-radius: 0.35em;
          }
        }
      }

      .chat_message_images_container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.5em -0.5em -0.5em;

        .chat_message_image {
          margin: 0.5em;
          cursor: pointer;
          display: flex;
          align-items: center;
          position: relative;

          img {
            width: 20em;
            height: auto;
            max-width: 100%;
            object-fit: cover;
            border: 1px solid $message-border;
            border-radius: 0.5em;
            background-color: #ffffff;
          }

          &:hover {
            .chat_actions_hover {
              display: flex;
            }
          }
        }
      }

      .chat_message_file {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        border-radius: 0.3rem;
        cursor: pointer;
        color: $defaultTextColor;
        font-size: 0.9rem;
        width: 22.2em;
        justify-content: space-between;
        margin-top: 0.5rem;
        background-color: white !important;
        height: 2.8rem;
        padding: 0 0.2rem;
        border: 1px solid $message-border;

        .name {
          max-width: 12rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          .chat_actions_hover {
            display: flex;
          }
        }
      }
    }
  }

  .empty-container {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 1em;
    cursor: default !important;
    @media screen and (max-width: $md) {
      text-align: center;
    }

    svg {
      color: $defaultTextColor;
    }

    h2 {
      font-size: 1.07em;
    }
  }
}

.chat_message_icon_name {
  gap: 0.2rem;
  align-items: center;
  display: flex;
  &--deletable {
    margin: 0.5rem 0;

    .name {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.message-delete-preview {
  border: solid 1px $lightgrey;
  border-radius: 8px;
  padding: 0.5em;
  margin-top: 0.5rem;
  overflow-x: auto;
  ul {
    margin: 0;
  }

  p {
    margin: 0 !important;
  }

  .chat_message_images_container {
    .chat_message_image {
      margin: 0.5rem 0;
      display: flex;
      gap: 0.2rem;
      img {
        max-height: 5rem;
        max-width: 5rem;
      }
    }
  }
}

.chat_message_reaction_container {
  display: flex;
  justify-content: flex-start;
  gap: 0.3rem;
  margin-top: 0.2rem;
  flex-wrap: wrap;
  color: $defaultTextColor;

  .chat_message_reaction_bubble {
    height: 1.5rem;
    display: flex;
    background-color: $extraLightgrey;
    border: solid $tertiaryColor 1px;
    border-radius: 0.5rem;
    padding: 0.5rem;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;

    &:hover {
      background-color: $secondaryColorLighten;
      border: solid $secondaryColor 1px;
    }

    .reaction_count {
      font-size: 0.7em;
    }

    .emoji-icone {
      height: auto;
    }
  }

  .chat_message_reaction_bubble_active {
    background-color: $secondaryColorLighter;
    border: solid $secondaryColor 1px;
  }
}

@media (max-width: $xxl) {
  .chat_messages {
    padding: 1em 10em !important;
  }
}

@media (max-width: $laptop) {
  .chat_messages {
    padding: 1em 3em !important;
  }
}

@media (max-width: $md) {
  .chat_messages {
    padding: 1em 2em !important;
  }
}

.seen-modal-list {
  max-height: 20rem;
  overflow-y: auto;

  .item {
    display: flex;

    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          font-size: 1em;
          line-height: 1.2;
        }

        .mail {
          font-size: 0.85em;
          color: $antdGreyListMetaDescription;
          line-height: 1.2;
        }
      }

      .date {
        font-size: 0.9em;
      }
    }
  }
}

@media (max-width: $md) {
  .chat_message_author_icon {
    display: none !important;
  }
}

.messages-drawer .ant-drawer-body .chat_messages {
  padding: 1em !important;

  .chat_message_author_icon {
    display: none !important;
  }
}
