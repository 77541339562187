.preview {
  .design-tool {
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: scroll;
    margin: 1em 2em 4em 1em;
  }

  a {
    color: var(--color-secondary);

    &:hover {
      color: var(--color-secondary-lighten);
    }
  }
  .styled-icon {
    fill: var(--color-primary);
    stroke: var(--color-primary);
  }

  /** Form **/
  /* Input */
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-input-number,
  .ant-picker {
    &:focus,
    &-focused,
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: var(--color-secondary) !important;
    }

    &:focus,
    &-focused {
      box-shadow: 0 0 2px var(--color-secondary);
    }
  }
  /* Required Asterisk */
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    color: var(--color-secondary);
  }

  /* Select */
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ) {
    &.ant-select-focused .ant-select-selector {
      box-shadow: 0 0 2px var(--color-secondary);
    }
    &.ant-select-open .ant-select-selector,
    &.ant-select-focused .ant-select-selector,
    &:hover .ant-select-selector {
      border-color: var(--color-secondary);
    }
  }

  /** Dropdown **/
  &.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    background-color: var(--color-secondary-lighter) !important;

    &:hover {
      background-color: var(--color-secondary-lighter) !important;
    }
  }
  /** Modal **/
  .ant-btn.ant-btn-primary {
    background-color: var(--color-secondary);
    color: var(--color-secondary-text);

    &:hover {
      background-color: var(--color-secondary-lighten);
      color: var(--color-secondary-text);
    }
  }

  .ant-btn.btn-secondary {
    background-color: var(--color-primary);
    color: var(--color-primary-text);

    &:hover {
      background-color: var(--color-primary-lighten);
      color: var(--color-primary-text);
    }
  }

  .ant-modal .ant-modal-content .ant-modal-footer .ant-btn-default,
  .ant-btn-default {
    background-color: var(--color-tertiary);
    color: var(--color-tertiary-text);

    &:hover {
      background-color: var(--color-tertiary-lighten);
      color: var(--color-tertiary-text);
    }
  }

  /** Menu **/
  .ant-menu-item.ant-menu-item-selected {
    border-right: solid 2px var(--color-secondary);

    .ant-menu-title-content {
      color: var(--color-secondary);
    }
  }

  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: var(--color-secondary-lighter);
  }
  .ant-menu .ant-menu-item .ant-menu-item-icon,
  .account-icon {
    color: var(--color-secondary);
  }

  .Auth {
    background-image: var(--background-image);
  }

  .Antechamber {
    background: var(--color-primary);
    color: var(--color-primary-text);

    .Antechamber-main-title {
      color: var(--color-primary-text);
    }

    hr {
      border-bottom: thin solid var(--color-secondary);
    }

    .Antechamber-webcam-container {
      .media-control-container {
        background: var(--color-secondary) !important;

        &.control-off {
          background: var(--color-primary) !important;
          border: thin solid var(--color-primary-text) !important;
        }
      }
    }

    .Antechamber-back {
      color: var(--color-primary-text);
    }

    .ant-select-dropdown
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: var(--color-secondary-lighter) !important;
    }
  }

  .fake-video-container {
    &--left {
      padding: 0.5rem 0.25rem 0.5rem 0.5rem;
    }

    &--right {
      padding: 0.5rem 0.5rem 0.5rem 0.25rem;
    }
  }

  .room_page {
    background-color: var(--color-primary);
  }

  .call-controls {
    background-color: var(--color-primary) !important;
  }

  .banner-content {
    background-color: var(--color-primary) !important;
  }
}
