@import '../../assets/scss/core/variables';

.Invite {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  background: linear-gradient(349deg, $primaryColorLighten 0%, $primaryColor 100%);
  color: $primaryColorText;

  .invite-title-container {
    display: flex;
    justify-content: space-between;
    justify-content: center;

    .logo-container {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }

    .invite-logo {
      max-width: 11rem;
      margin-left: 3.5rem;
    }

    .language-icon {
      margin: 0;

      .language-text {
        color: $primaryColorText;
      }
    }
  }

  .invite-content {
    width: 38rem;
    padding: 1rem;
    max-width: 100%;

    .invite-auth-methods {
      margin-top: 1rem;
      text-align: center;

      .invite-content-separator-container {
        text-align: center;

        .invite-content-separator {
          height: 100%;
          border-right: 1px solid $lightgrey;
          width: 0;
          display: inline-block;
        }
      }

      .invite-secure-access-container {
        img {
          max-width: 100%;
        }
      }
    }

    .feedback-container {
      text-align: center;
    }
  }

  @media screen and (max-width: $sm) {
    .invite-auth-methods {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .invite-content-separator-container {
      display: none;
    }

    .invite-secure-access-container {
      margin-top: 1rem;
      max-width: 20rem;
    }
  }
}
