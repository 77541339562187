.add-button {
  width: 100%;
  font-weight: 600;
  margin-top: 1rem;
}

.add-modal {
  .ant-form {
    margin-top: 1rem;
    margin-bottom: 1em;
  }
}
