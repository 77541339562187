@import '../../assets/scss/core/variables';

.RoomParticipantsFilter {
  .filter-container {
    font-size: medium;
    padding: 1rem 0 0 0;
  }

  .participants-list {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-top: 0.5em;
  }

  .participants-block {
    .participants-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.5em;
      margin-right: 1em;

      .decline {
        color: $defaultTextColor;
        margin-right: 0.4em;

        &:hover {
          color: $defaultTextColorLighten;
        }
      }
    }

    .participants-block-header-actions {
      display: flex;
    }
  }

  .waiting-empty {
    margin-top: 1rem;
  }

  .room-participant-item-status-dot {
    display: inline-block;
    margin-top: 2px;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
  }

  .status-dot--pending {
    background-color: $pendingStatus;
  }

  .status-dot--online {
    background-color: $onlineStatus;
  }

  .status-dot--offline {
    background-color: grey;
  }

  .circular-dots-btn {
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e5f1ff !important;
    }
  }
}

.delete-btn-containter {
  flex: 1;
  .delete-btn {
    margin-inline-start: 0 !important;
  }
}

.room-participant-item-body-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
}
