.messages_page.layout {
  height: 100vh;

  main {
    .content {
      aside {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
      }

      article {
        padding: 0;

        @media (max-width: 992px) {
          padding: 0 !important;
        }

        .messages_content {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .chat_disabled {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50%;
          flex-direction: column;
          gap: 1em;
        }
      }
    }
  }
}
