@import '../../../assets/scss/core/variables';

.round_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background-color: $lightgrey;
    color: $textColor;
  }
}
