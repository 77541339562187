@import '../../assets/scss/core/variables';
.question {
  color: $textColor;
  margin: 0.5em;
}
.children {
  color: $textColor;
  margin-bottom: 0.5em;
}

.line-1 > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  padding-right: 0.6em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  word-break: break-all;
}
