@import '../../../assets/scss/core/variables';

.message-input-container {
  position: relative;
  border: 1px solid;
  padding: 0.25em 0.25em 0 0.25em;
  border-color: $lightgrey;
  border-radius: 0.3em;
  background-color: #fff;
  &--no-message {
    #message-input-toolbar {
      .ql-send {
        background-color: $lightgrey;
        color: $grey;
        cursor: not-allowed;
        &:hover {
          color: $grey;
          background-color: $lightgrey;
        }
      }
    }
  }

  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .ant-upload-list-item-container {
    float: left;
    width: fit-content;
    &-min {
      width: 5em;
      height: 2em;
    }
    .ant-btn-text {
      border-radius: 50%;
    }

    .ant-upload-list-item {
      background-color: white;
      float: left;
      // width: 200px;
      width: 12em;
      height: 4em !important;
      margin-inline-end: 0.5em;

      &:hover {
        background: $lightgrey !important;

        a,
        span {
          text-decoration: underline;
        }
      }

      a,
      span {
        color: $defaultTextColor;
        cursor: pointer;
      }
    }
  }
}

.citation-container {
  margin: 0.5em;
  position: relative;
  &-content {
    .cancel-btn {
      position: absolute;
      top: 0.5em;
      right: 0.5em;

      cursor: pointer;
      // padding: 0;
      // margin: 0.25em;
    }
    background-color: $primaryColorExtraLighter;
    border-radius: 0.5em;
    padding: 1.25em;
    padding-top: 2.5em;
    p {
      margin: 0;
    }
  }
  // position: relative;
}

#message-input-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0;

  .picker-container {
    position: absolute;
    z-index: 9999;
    top: auto;
    bottom: 100%;
    left: 0;
    transform: translateY(-10px);
  }

  .message-input-toolbar-left {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: $defaultTextColor;

    .ant-divider {
      background: $grey;
      font-size: 1.3rem;
    }
  }

  .ql-send {
    color: white;
    background-color: $secondaryColor;
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 20%;
    margin-right: 0.5em;
    svg {
      padding-right: 0.6em;
    }

    &:hover {
      background-color: lighten($secondaryColor, 10%);
    }
  }

  .ql-bold,
  .ql-italic,
  .ql-upload {
    color: $defaultTextColor;

    path,
    line,
    rect {
      stroke: $defaultTextColor;
    }

    &:hover {
      color: $secondaryColor;

      path,
      line,
      rect {
        stroke: $secondaryColor;
      }
    }
  }

  /** Specifics ql element **/

  .ql-underline {
    path {
      stroke: $defaultTextColor;
    }
    .ql-fill {
      fill: $defaultTextColor;
    }

    &:hover {
      path {
        stroke: $secondaryColor;
      }
      .ql-fill {
        fill: $secondaryColor;
      }
    }
  }

  .ql-strike {
    path {
      fill: $defaultTextColor;
    }
    .ql-stroke {
      stroke: $defaultTextColor;
    }

    &:hover {
      path {
        fill: $secondaryColor;
      }

      .ql-stroke {
        stroke: $secondaryColor;
      }
    }
  }

  .ql-list {
    .ql-thin {
      stroke: $defaultTextColor;
    }

    .ql-fill {
      fill: $defaultTextColor;
      stroke: none;
    }

    path,
    line,
    rect {
      stroke: $defaultTextColor;
    }

    &:hover {
      .ql-thin {
        stroke: $secondaryColor;
      }

      .ql-fill {
        fill: $secondaryColor;
        stroke: none;
      }

      path,
      line,
      rect {
        stroke: $secondaryColor;
      }
    }
  }
}
