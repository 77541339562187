@import '../../assets/scss/core/variables';
$drawerWidth: 23.75rem;
$callControlHeight: 4em;

.closed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primaryColor;
}

.room_page {
  background-color: $primaryColor;

  body {
    background-color: $primaryColor;
  }

  &--drawer-opened {
    .Peers {
      width: calc(100vw - $drawerWidth); //drawer size
    }

    .DecreasedPeers {
      width: calc(100vw - $drawerWidth); //drawer size
    }

    .PromotedPeer {
      width: calc(100vw - $drawerWidth); //drawer size
    }
  }

  .call-controls-container {
    position: fixed;
    bottom: 0;
    z-index: 299;
    height: $callControlHeight;
    width: 100%;

    .call-controls {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      background-color: $primaryColor;
      padding: 0 1em;
      width: 100%;
      height: 100%;
      align-items: center;

      &--left {
        display: flex;
        justify-content: left;
        align-items: center;
        overflow: hidden;

        .logo {
          width: 4em;
          flex-shrink: 0;
        }

        .info-container {
          display: flex;
          flex-direction: column;
          margin-left: 0.5em;
          overflow: hidden;

          .room-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: white !important;
            margin-bottom: 0;
          }

          .time {
            margin-bottom: 0;
            color: white !important;
          }
        }
      }

      &--middle {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }

      &--right {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
      }
      .call-control {
        display: flex;
        width: 3rem;
        height: 3rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        //border: 1px solid #fff;
        transition: background-color 0.3s ease;

        &:not(&--hangup):not(&--active):hover {
          background-color: $primaryColorDarker;
        }

        &--hangup {
          background-color: #e12727;
          border: none;
        }

        &--disabled {
        }

        &-attention {
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
        }

        &--active {
          background-color: $secondaryColor;
          border: none;
        }

        &--inactive {
          background-color: transparent;
        }

        &--primary {
          border: 1px solid $primaryColor !important;
        }

        .users {
          width: 2rem;
        }

        img {
          width: 2.5rem;
        }

        &-white {
          background-color: white;
        }
      }
    }
  }
}

.participants-drawer h4.uppercase {
  margin-bottom: 0;
}

.room-drawer {
  height: calc(100% - 4.5em) !important;
  padding: 0.25em 0.375em 0.25em 0;
  border-radius: 0.5em;

  .ant-drawer-header {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .ant-drawer-body {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
}

.participants-drawer,
.documents-drawer,
.setting-drawer,
.messages-drawer {
  overflow: visible !important; //to display link in message input

  h4.uppercase {
    letter-spacing: 1px;
  }

  .ant-list-item-meta-avatar {
    margin: auto;
    margin-left: 0.5rem;
  }

  .ant-list-item:hover {
    background-color: $tertiaryColorLighten;
  }

  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;

    div {
      color: $textColor;
      font-size: 1.1rem;
    }

    button {
      color: $secondaryColor;
    }
  }

  .ant-drawer-body {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;

    .ant-upload.ant-upload-drag {
      border-style: solid;
      padding: 0 2rem;
      font-size: 0.5rem;

      .ant-upload-text {
        font-size: 0.9rem;
      }
      .ant-upload-hint {
        font-size: 0.8rem;
      }
    }

    .participants-drawer-collapse-header-badge {
      border-radius: 50%;
      background-color: $lightgrey;
      min-width: 1rem;
      height: 1rem;
      line-height: 1rem;
      font-size: 0.7rem;
      text-align: center;

      &--waiting {
        background-color: $secondaryColor;
        color: $secondaryColorText;
      }

      &--connected {
        background-color: $successColor;
        color: #ffffff;
      }

      &--not-connected {
        background-color: $errorColor;
        color: #ffffff;
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-block: 0 !important;

      .ant-form-item {
      }
    }

    .ant-collapse-content-box {
      padding: 0 1rem;
      padding-block: 0 !important;

      .ant-form-item {
        margin-bottom: 1rem;
      }
    }

    &-actions {
      &-buttons {
        cursor: pointer;
      }
    }
  }

  .panel-collapse-icon {
    font-size: 1rem !important;
    color: $secondaryColor !important;

    &--active {
      transform: rotate(180deg);
    }
  }
}

.setting-drawer,
.participants-drawer {
  .ant-drawer-body {
    padding: 0 2em;
  }
}

.call-control-dropdown {
  display: flex;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-radius: 50%;

  svg {
    path {
      color: $primaryColor;
      fill: $primaryColor;
    }
  }

  .users {
    width: 2rem;
  }
}

.refresh-container > span {
  padding-left: 0.3em;
}

@media (max-width: $l) {
  .PromotedPeer {
    width: 100% !important;
  }
}

@media (max-width: 1120px) {
  .PromotedPeer {
    width: 100% !important;
  }
}

@media (max-width: $md) {
  .call-controls-container .call-controls {
    grid-template-columns: 1fr 1fr !important;
  }
}

.room-participant-item-actions {
  display: flex;
  align-items: center;
}

.room-participant-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #fafafa;
  }
}

.room-participant-item-body {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 0;
}
