@import '../../assets/scss/core/variables';

.RoomConnectionError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  color: $primaryColorText;
  height: 100%;

  .title {
    color: white;
  }

  .reconnection-container {
    p {
      color: white;
    }

    .explanation {
      max-width: 60em;
    }

    .reconnection-message {
      color: white;
      text-align: center;
    }
  }
}
