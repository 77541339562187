@import '../../../assets/scss/core/variables';

.disabled-row {
  pointer-events: none;

  & > * {
    background-color: $extraLightgrey !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $extraLightgrey;
    border-color: $lightgrey;
  }

  .ant-checkbox:hover * {
    background-color: $extraLightgrey !important;
    border-color: $lightgrey !important;
  }

  .ant-checkbox-inner::after {
    border-color: $tertiaryColor;
  }
}

.AddUserGroup {
  display: flex;
  flex-direction: column;
  max-height: 31.5em;

  .table {
    overflow-y: auto;
  }
}
