@import '../../../assets/scss/core/_variables';

.chat_file_upload_overlay {
  z-index: 3; //because chat_message_date_separator
  position: absolute;
  top: 3.5em;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;

  .close_dragger_container {
    display: flex;
    justify-content: end;

    .action-rounded-icon {
      margin-top: 0.5em;
      margin-right: 0.5em;

      .close_dragger {
        z-index: 1;
        cursor: pointer;
      }
    }
  }

  .chat_file_upload {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    opacity: 0.95;

    .chat_file_upload_dragger {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;

      .ant-upload {
        padding: 0;
        flex-shrink: 1;
        flex-grow: 1;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-upload-drag-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: space-between;
          height: 100%;
          width: 100%;

          .chat_file_upload_middle {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: -1;
          }
        }
      }

      .ant-upload-list {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 20em;

        .ant-upload-list-item {
          padding: 1.2em 0.8em;

          .ant-upload-list-item-progress {
            width: calc(100% - 1.6em);
          }
        }
      }
    }

    .chat_file_upload_actions {
      padding: 1em;
      display: flex;
      justify-content: end;
    }
  }
}
