@import '../../../assets/scss/core/variables';

$treeTextColor: #777777;

.DrivesMenu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 10px;

  .menu_group {
    .menu_group_title-container {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .title {
        margin-left: 0.7em;
        color: $defaultTextColor !important;
        font-weight: 400;
      }
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 0 1em;
      height: 3.5em;
      font-size: 16px;
      border-radius: 0 !important;
      border-bottom: none;

      .ant-collapse-expand-icon {
        padding-right: 0.4em;
      }

      .ant-collapse-header-text {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1em;
        font-weight: 600;
        color: #3c3c3c;
        border-bottom: none;

        .ant-tag {
          font-weight: normal;
          font-size: 1em;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-block: 0 !important;
    }
  }

  &-drive-title-container {
    font-weight: 600;
    text-transform: uppercase;
    color: $defaultTextColor !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-drive {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .group-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .folder-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    gap: 0.3em;

    .title {
      margin-top: 2px;
    }

    .ant-spin-dot-item {
      background-color: $defaultTextColor !important;
    }
  }

  &-drive-title-icon {
    color: $defaultTextColor !important;
  }

  .ant-tree-list-holder {
    background-color: $extraLightgrey !important;
  }

  &-main-title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 2.35em;
    padding-left: 0.6em;
    color: $primaryColor;
    font-weight: 600;
  }

  &-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $textColor;
    gap: 0.2em;
    margin-bottom: 0.1em;
    padding-top: 0.4em;
    padding-left: 0.4em;

    .driveTitle {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      color: $defaultTextColor !important;
      margin-bottom: 0.1em;
    }
  }

  .folder-icon {
    width: 1.3em;
  }

  .ant-tree-title {
    color: $defaultTextColor;
  }

  .anticon svg {
    color: $defaultTextColor;
  }
}

.menu-create-drive {
  label {
    font-size: 1.07em !important;
    color: $defaultTextColor;
  }
}

@media (max-width: $laptop) {
  .DrivesMenu {
    width: 100%;
  }
}
