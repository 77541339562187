@import '../../assets/scss/core/variables';

.Maintenance {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;

  .documents-banner {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: end;
    align-items: center;
    color: $textColor;
    text-align: center;
    z-index: 1;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .layer {
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo {
    width: 16rem;
    max-width: 100%;
    z-index: 999;
  }

  .logo-container {
    z-index: 999;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 1em;
    background-color: #fff;
    border-radius: 1rem;
    margin-top: 2rem;
    position: relative;
    z-index: 999;

    h1,
    h3 {
      margin: 0;
    }

    .icon {
      color: $primaryColor;
      display: inline-block;
    }
  }
}
