@import '../../assets/scss/core/variables';

.DrivesSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .autocomplete-container {
    width: 100%;

    .ant-select {
      width: 100%;
    }
  }
}

.drive-option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .option-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option-name {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.6em;
  }

  .option-date {
    color: $defaultTextColor;
    font-size: 0.8em;
  }
}
