@import '../../assets/scss/core/variables';

.margin-right-col {
  margin-right: 1em;
}

.input-filter {
  display: block;
  margin-bottom: 0.3em;
  width: 12rem;
}

.cancel-button {
  width: 9em;
  height: 2.5em !important;
  margin-bottom: 0.25rem;
}

.center-space {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.change-on-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}
