.DrivesList {
  display: flex;
  flex-direction: column;
  gap: 2em;

  .title {
    text-align: left;
  }

  &--container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    gap: 1em;
    width: 100%;
  }

  &--item {
    box-sizing: border-box;
  }
}
