@import './common';
@import '../../../assets/scss/core/variables';

.chat_title {
  overflow: hidden;
  display: flex;

  .chat-label {
    font-size: 1.1em;
    font-weight: 600;
    color: #353535;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat_title_avatar_container {
    flex-shrink: 0;
    position: relative;

    .chat_title_avatar {
      @extend .chat_icon;
      margin-right: 0.8rem;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .chat_item_p2p_online {
      content: ' ';
      display: block;
      width: 0.8em;
      height: 0.8em;
      border-radius: 50%;
      position: absolute;
      bottom: -0.1em;
      right: 0.2em;
      background-color: #52c41a;
      border: 1px solid #ffffff;
    }
  }
}

.chat_archive_btn {
  margin-left: 0.5rem;
  background-color: white !important;
  color: grey !important;
  border: none !important;
  &:hover {
    background-color: white !important;
    color: $secondaryColor !important;
  }
}
