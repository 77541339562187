@import '../../assets/scss/core/variables';

.mobile-menu {
  .ant-menu-title-content {
    font-size: 1rem;
  }
  &-meetings {
    color: $auxameetings;

    span {
      color: $auxameetings !important;
    }

    svg {
      fill: $auxameetings;
    }
  }

  &-planning {
    color: $auxaplanning !important;

    span {
      color: $auxaplanning !important;
    }

    svg {
      fill: $auxaplanning;
    }
  }

  &-team {
    color: $auxateam !important;

    span {
      color: $auxateam !important;
    }

    svg {
      fill: $auxateam;
    }
  }

  &-drive {
    color: $auxadrive !important;
    span {
      color: $auxadrive !important;
    }

    svg {
      fill: $auxadrive;
    }
  }

  &-transfer {
    color: $auxatransfer !important;
    span {
      color: $auxatransfer !important;
    }

    svg {
      fill: $auxatransfer;
    }
  }

  &-default {
    color: $managment !important;
    span {
      color: $managment !important;
    }

    svg {
      fill: $managment;
    }
  }

  &-logo {
    width: 2rem;
  }
}

.mobile-menu-language-container {
  padding-left: 1.1rem;
  padding-bottom: 1rem;
}

.drawer-title {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    margin-top: 0.2rem;
  }
}

.drawer-disconnect-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .drawer-logout-btn {
    display: flex;
    align-items: center;
    border: none;
    color: #ffffff;
    padding: 0.5rem 1.1rem;

    svg {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
  }
}

.right-side-drawer {
  .ant-drawer-body {
    padding: 0;
    background-color: $extraLightgrey;
  }

  .title {
    margin-bottom: 0;
  }
}

.menu-mobile-footer {
  position: absolute;
  bottom: 0.5rem;
  text-align: center;
}
