.not-found {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 4rem;
    line-height: normal;
    margin: 0;
  }

  a {
    font-size: 2rem;
  }
}
