@import '../assets/scss/core/variables';

.CallQualityIndicator {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $callGrey;
  margin: 0.4em;
  padding: 0.2em;
  border-radius: 0.5em;
  color: white;

  .row {
    display: flex;
    align-items: center;
    color: white;
    gap: 0.2em;
    padding: 0.2em;

    .row-title {
      color: white;
      margin-bottom: 0;
    }
  }
}
