@import '../../assets/scss/core/variables';

.document-row {
  padding-bottom: 5em;
  &.scroll {
    height: 23em;
    overflow-y: auto;
  }

  .buttons:hover {
    color: $defaultTextColor !important;
  }
}

.document-list-file {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filename {
    min-width: 3rem;
    color: $textColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
  }

  .actions {
    display: flex;
  }
}

.ant-upload .ant-upload-drag-icon {
  font-size: 2.3rem;
  color: $secondaryColor;
  margin-bottom: 0rem !important;
}
