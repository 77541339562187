.timezone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;

  .select-timezone {
    width: 100%;
    max-width: 19rem;
    overflow: hidden;
  }
}
