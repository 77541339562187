@import '../../assets/scss/core/variables';

.notification_widget {
  cursor: pointer;
  position: relative;

  &_unseen_count {
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    background-color: #ff4d4f;
    color: #ffffff;
    border-radius: 1.4em;
    height: 1.4em;
    min-width: 1.4em;
    line-height: 1.4em;
    padding: 0 0.4em;
    text-align: center;
  }
}

.notification_widget_content {
  width: 20em;
  max-height: 60em;
  overflow-y: auto;

  &_actions {
    display: flex;
    justify-content: space-between;
    text-align: right;
    align-items: center;
  }

  .notification_widget_content_all {
    font-size: 0.7em;
    margin-bottom: 0.15em;
  }

  &_action {
    display: inline-flex;
    align-items: center;
    margin-left: 0.5em;
    font-size: 1.4em;
    cursor: pointer;
    color: $defaultTextColor !important;
    gap: 0.1em;
  }

  &_item {
    padding: 0.4em 0;
    border-top: 1px solid rgb(230, 230, 230);

    &_row_format {
      border-top: none;
      padding: 0;
    }

    :hover .notification_actions {
      display: block;
    }

    &_icon {
      display: flex;
      align-items: center;
      height: 2.5em;
      cursor: pointer;
      margin-top: 0.3em;
    }

    &_unseen {
      width: 0.8em;
      height: 0.8em;
      border-radius: 50%;
      background-color: #ff4d4f;
      margin-left: 0.4em;
    }

    &_unseen_grouped,
    &_grouped {
      width: 1.2em;
      height: 1.2em;
      border-radius: 50%;
      background-color: #ff4d4f;
      color: #ffffff;
      margin-left: 0.4em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &_grouped {
      background-color: rgb(187, 187, 187);
    }

    &_separator {
      margin-left: 0.4em;
    }

    &_chat_message_header {
      display: flex;
      justify-content: space-between;
      height: 1.8em;

      &_date {
        font-style: italic;
      }

      .notification_actions {
        flex-shrink: 0;
        align-items: center;
        @media screen and (max-width: $lg) {
          display: block;
        }
        display: none;
        margin-left: auto;

        .notification_widget_content_action {
          color: $defaultTextColor;
        }
      }
    }

    &_chat_message_body {
      display: flex;
      gap: 1em;

      .notification_chat_container,
      .notification_chat_text,
      .notification_chat_file {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .notification_chat_text_container {
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .notification_chat_container {
        color: $defaultTextColor !important;
        cursor: pointer;
      }
    }
  }
}

.notification_tooltip {
  .ant-tooltip-arrow::before  {
    background: white;
  }
  .ant-tooltip-inner {
    max-width: 40em;
  }
}

@media (max-width: $laptop) {
  .notification_widget_content {
    max-height: 40em;
    overflow-y: auto;
  }
}

@media (min-width: $sm) {
  .notification_widget {
    display: none;
  }
}

@media (max-width: $sm) {
  .notification_widget_content {
    margin-top: -1rem;
  }
}
