@import '../../assets/scss/core/variables';

.menu-navigation-container {
  display: flex;
  flex-direction: column;

  .main-title {
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: center;
  }

  .menu-navigation-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5em;
    padding: 0.4em 0.8em 0.8em 0.8em;

    .menu-navigation-element {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      cursor: pointer;
      line-height: 1.3em;
      color: black;

      &--active-meeting {
        .menu-navigation-logo-container {
          border: solid 1px $auxameetings !important;
        }
      }

      &--active-calendar {
        .menu-navigation-logo-container {
          border: solid 1px $auxaplanning !important;
        }
      }

      &--active-messages {
        .menu-navigation-logo-container {
          border: solid 1px $auxateam !important;
        }
      }

      &--active-drives {
        .menu-navigation-logo-container {
          border: solid 1px $auxadrive !important;
        }
      }

      &--active-transfer {
        .menu-navigation-logo-container {
          border: solid 1px $auxatransfer !important;
        }
      }

      &--active-organisation {
        .menu-navigation-logo-container {
          border: solid 1px $managment !important;
        }
      }

      &--active-faq {
        .menu-navigation-logo-container {
          border: solid 1px $managment !important;
        }
      }

      .menu-navigation-logo-container {
        background-color: white;
        padding: 0.3em;
        border-radius: 0.3em;
        width: 5em;
        height: 4.3rem;
        border: solid 1px $lightgrey;

        .menu-navigation-logo {
          width: 100%;
          height: 4em;
          object-fit: contain;

          &.user {
            color: $managment;
            height: 3rem;
            padding-top: 0.5rem;
          }
        }
      }
    }

    .menu-navigation-element:hover {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.header-action-rounded-icon {
  cursor: pointer;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background-color: $lightgrey;
    color: $textColor;
  }
}
