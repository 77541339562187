@import '../../assets/scss/core/variables';

.menu-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 3em;
  gap: 1.2em;

  .avatar {
    background-color: $primaryColor;
    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    cursor: pointer;
    font-size: 1.5em;
    border: solid 3px white;
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.1em;

    .email {
      font-weight: 600;
    }
  }
}
