@import '../../../assets/scss/core/variables';

.Call {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  height: 100%;
  background-color: #224160;

  &__remote_video {
    display: flex;
    height: 100%;
    max-width: 100%;
    flex-shrink: 10;
    flex-basis: 50px;
    flex-grow: 1;
    object-fit: cover;
  }

  &__local_video {
    position: absolute;
    bottom: 1em;
    right: 1em;
    width: 18em;
  }

  &__controls-container {
    position: fixed;
    bottom: 2rem;
    right: calc(50%);
    transform: translate(50%, 0);
    z-index: 299;

    .call-controls {
      display: flex;
      margin: 0 5rem;
      background-color: $primaryColor;
      padding: 0.7rem;
      border-radius: 4rem;
      box-shadow: 2.5px 2.5px 10px black;
      gap: 3rem;

      &--left {
        display: flex;
        justify-content: left;
        gap: 0.5rem;
      }

      &--middle {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }

      &--right {
        display: flex;
        justify-content: right;
        gap: 0.5rem;
      }

      .call-control {
        display: flex;
        width: 3.5rem;
        height: 3.5rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        border: 1px solid #fff;

        &--hangup {
          background-color: #e12727;
          border: none;
        }

        &--disabled {
        }

        &-attention {
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
        }

        &--active {
          background-color: $secondaryColor;
          border: none;
        }

        &--inactive {
          background-color: transparent;
        }

        &--primary {
          border: 1px solid $primaryColor !important;
        }

        .users {
          width: 2rem;
        }

        img {
          width: 2.5rem;
        }

        &-white {
          background-color: white;
        }
      }
    }
  }
}
