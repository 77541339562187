@keyframes websocket-disconnected-color {
  0% {
    background-color: #ffcec6;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #ffcec6;
  }
}

.websocket-disconnected {
  animation: websocket-disconnected-color 1s infinite linear;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f !important;
}
