@import '../../../assets/scss/core/variables';

.Antechamber {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primaryColorText;

  .Antechamber-header-container {
    width: 100%;
    min-height: 8em;

    .Antechamber-header {
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
    }

    .invited {
      padding: 2em 0;
    }

    .Antechamber-main-title-container {
      display: flex;
      flex-direction: column;

      .Antechamber-main-title {
        color: $primaryColorText;
        font-weight: bold;
        margin-bottom: 0.5rem;

        &--invited {
          font-weight: 500 !important;
        }
      }
    }

    .Antechamber-room-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.2em;
    }
  }

  .Antechamber-main {
    width: 100%;
    background-color: $primaryColorDarker;
    // padding: 0 30%;

    .Antechamber-connection-error {
      text-align: center;
      margin-bottom: 2.45rem;
    }
  }

  .Antechamber-join-button-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 1em;

    .back-btn {
      background-color: unset;
      border-color: white;
      color: white !important;

      &:hover {
        background-color: unset;
        color: $secondaryColor !important;
        border-color: $secondaryColor !important;
      }
    }

    .join-btn {
      width: 100%;

      .sovereignty-img-container {
        justify-content: center;
        gap: 1rem;

        .sovereignty-img {
          width: 4rem;
        }
      }
    }
  }

  @media (min-width: 3000px) {
    .Antechamber-header-container {
      padding: 0 15%;
    }
    .Antechamber-main {
      padding: 0 15%;
    }
  }

  @media (max-width: $md) {
    .Antechamber-main {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-col {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .Antechamber-header-container {
      height: 6em;
    }
  }

  @media (max-width: $sm) {
    .Antechamber-header-container {
      display: flex;
      justify-content: center;
    }
  }
}
