@import '../../assets/scss/core/variables';

.notifications_page {
}

.notification_header {
  &_actions {
    &_item {
      display: flex;
      justify-content: center;
      align-items: end;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: $secondaryColor;
      }
    }
    &_text {
      margin-bottom: 0.1em;
      margin-left: 0.1em;
    }

    &_hblue {
      &:hover {
        color: $secondaryColor !important;
      }
    }

    &_hred {
      &:hover {
        color: $errorColor !important;
      }
    }
  }
}

.notification_menu {
  &_icon {
    height: 1.8em;
  }

  &_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_Meetings {
      color: $auxameetings;
    }
    &_Planning {
      color: $auxaplanning;
    }
    &_Team {
      color: $auxateam;
    }
    &_Drive {
      color: $auxadrive;
    }
    &_Transfer {
      color: $auxatransfer;
    }
  }

  &_to_align {
    margin-top: 0.5em;
  }

  &_divider {
    margin: 0 0 0.2em 0.8em;
    background-color: $primaryColor;
  }
}

.notification_table {
  &_icon_column {
    width: 5em;
    min-width: 0rem !important;
  }

  &_date_column {
    width: 0;
  }

  &_message_column {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.07em;

    &_content_chat {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      gap: 0.5em;
    }

    &_content_drive {
      display: flex !important;
      align-items: center;
    }
  }

  &_actions_column {
    width: 11em;
    > div {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
}
