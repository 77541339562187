@import '../../../assets/scss/core/variables';

.new-meeting {
  max-height: 45rem;
}

.form-meeting-container {
  max-width: 50rem;

  .ant-tree .ant-tree-node-content-wrapper {
    &:hover {
      background-color: transparent;
    }
    cursor: default;

    .tree-dates span {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        cursor: pointer;
        color: red;
      }
    }

    span.tree-dates.placeholder {
      color: grey;
    }
  }

  &.sticky-create-meeting {
    position: fixed;
    padding: 1rem 0;
    bottom: 0rem;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;

    &--calendar {
      position: sticky !important;
      width: unset !important;
      padding-bottom: 0;
    }

    &.align-with-others > * {
      width: 96%;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .submit-button {
    display: flex;
    justify-content: center;
  }

  &.align-with-others > * {
    flex: inherit;
    width: 98%;
  }
}

.radio-padding {
  .ant-radio-wrapper {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
  }
}
