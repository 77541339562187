@import '../../assets/scss/core/variables';

.ReinitPassword {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;

  .layer {
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo {
    width: 16rem;
    max-width: 100%;
    z-index: 999;
    pointer-events: none;
  }

  .logo-container {
    z-index: 999;
    text-align: center;
  }

  .auth-methods {
    display: flex;
    margin: 3rem auto 1rem auto;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 1rem;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 22rem;

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .login-label {
      color: $textColor;
    }

    .ant-form-item-label > label {
      font-weight: bold;
    }

    .auth-misc-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-affix-wrapper {
    padding: 0.7em; //like btn
    font-size: 1em;
  }

  .ant-btn-text {
    font-size: 0.7rem;
  }
}

.password-requirements {
  white-space: pre-wrap;
}
