@import '../../assets/scss/core/variables';

.text-area-editor {
  .ql-container {
    height: 5.5rem;
    min-height: 5.5rem;
    overflow-y: auto;
    resize: vertical;
  }
}
