@import './variables.scss';
@import './common';
@import '../../../assets/scss/core/variables';

.filter-container {
  display: flex;
  gap: 0.5em;

  .filter-button {
    border-radius: 0.9em;
    padding: 0.4em 1em;
    text-align: center;
    font-size: 0.8em;
    color: $defaultTextColor;
    background-color: $lightgrey;
    cursor: pointer;

    &.active {
      background-color: $secondaryColor;
      color: #ffffff;
    }
  }
}

.ChatListComponent {
  flex-grow: 1;
  overflow: hidden;
  flex-basis: 10px;
  background-color: $extraLightgrey;

  .chat_group {
    .chat_group_title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      font-weight: normal;
      color: #3c3c3c;
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 0 1em;
      padding-bottom: 0.1em;
      font-size: 16px;
      border-radius: 0 !important;
      border-bottom: none;

      .ant-collapse-expand-icon {
        padding-right: 0.4em;
      }

      .ant-collapse-header-text {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1em;
        font-weight: 600;
        color: #3c3c3c;

        .ant-tag {
          font-weight: normal;
          font-size: 1em;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-block: 0 !important;
    }
  }

  .chat_item {
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0.4em;
    cursor: pointer;
    border-radius: 0.5em;
    margin: 0.2em;

    &:hover,
    &--current {
      background-color: white;

      .chat_item_details {
        .chat_item_last_message,
        .chat_item_no_messages {
          &::after {
            box-shadow: 0px 0px 25px 30px white;
            background-color: white;
          }
        }
      }
    }

    // &--archived {
    //   background-color: #d9d9d9;
    //   color: #797979;

    //   .chat_item_details {
    //     .chat_item_last_message,
    //     .chat_item_no_messages {
    //       &::after {
    //         box-shadow: 0px 0px 25px 30px #d9d9d9;
    //         background-color: #d9d9d9;
    //       }
    //     }
    //   }

    //   .chat_archived {
    //     line-height: 1em;
    //     margin-left: 0.5em;
    //     padding: 0 0.4em;
    //   }
    // }

    &--unread {
      .chat_item_name {
        font-weight: 700;
      }
    }

    &--hide {
      display: none;
    }

    .chat_item_avatar_container {
      flex-shrink: 0;
      position: relative;

      .chat_item_avatar {
        @extend .chat_icon;
        flex-shrink: 0;
        margin-right: 0.6em;
      }

      .chat_item_p2p_online {
        content: ' ';
        display: block;
        width: 0.8em;
        height: 0.8em;
        border-radius: 50%;
        position: absolute;
        bottom: -0.1em;
        right: 0.2em;
        background-color: #52c41a;
        border: 1px solid #ffffff;
      }
    }

    .ant-avatar {
      background-color: #ffffff;
    }

    .chat_item_details {
      flex-grow: 1;
      overflow: hidden;
    }

    .chat_item_details_first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1.05em;

      .chat_item_title {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        color: $defaultTextColor;
      }
    }

    .chat_item_details_second {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1em;

      .draft-indicator {
        white-space: nowrap;
        color: $errorColor;
        height: 1.4em;
        line-height: 1.3em;
        font-size: 0.8em;
      }
      .ant-tag {
        margin-inline-end: 0;
      }
    }

    .chat_item_last_message_date {
      font-size: 0.8em;
      margin-left: 1em;
      color: #aaaaaa;
      margin-right: 0.1em;
    }

    .chat_item_unread_messages_count {
      border-radius: 1.5em;
      color: #ffffff;
      background-color: $secondaryColor;
      min-width: 1.5em;
      height: 1.5em;
      font-size: 0.75em;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4em;
      font-weight: bold;
    }

    .chat_item_last_message,
    .deleted_message_content,
    .chat_item_no_messages {
      overflow: hidden;
      height: 1.4em;
      line-height: 1.3em;
      color: #797979;
      position: relative;
      width: calc(100% - 40px);
      font-size: 0.8em;
      text-overflow: ellipsis;
      white-space: nowrap;

      > * {
        vertical-align: middle;
      }

      /* Fade effect */
      // &::after {
      //   content: ' ';
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   height: 100%;
      //   width: 2em;
      //   box-shadow: 0px 0px 25px 30px $extraLightgrey;
      //   background-color: $extraLightgrey;
      // }
    }
  }
}
