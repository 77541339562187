@import '../../assets/scss/core/variables';

.Peer {
  width: 100%;
  height: 100%;
  background-color: $primaryColor;
  box-sizing: border-box;
  padding: 0.25em;
  position: relative;

  video {
    background-color: $primaryColor;
  }
}
