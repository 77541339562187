@import '../../../assets/scss/core/variables';

$btn-colors: (
  'yes': $successColor,
  'maybe': $extraDarkGrey,
  'no': $errorColor,
);

.Participate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8em;

  .Participate-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    font-size: 1.3em;
    padding-top: 1em;

    .title {
      font-weight: bold;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    gap: 2em;

    @each $key, $color in $btn-colors {
      .#{$key} {
        color: $color !important;
        border-color: $color !important;
        font-weight: 600;
        width: 5em;

        &:hover {
          background-color: if(
            $key == 'yes',
            $successColorLighten,
            if($key == 'maybe', $lightgrey, $errorColorLighten)
          );
        }

        &--activate {
          background-color: $color;
          color: white !important;
        }
      }
    }
  }
}

@media (max-width: $laptop) {
  .Participate {
    padding: 0;
  }
}
@media (max-width: $sm) {
  .Participate {
    .Participate-subtitle {
      flex-direction: column;
      gap: 0;
    }
  }
}

@media (max-width: $xxs) {
  .Participate {
    .btn-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2em;
    }
  }
}
