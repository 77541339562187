.messages-drawer {
  .chat_drawer_title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chat_list_title {
      display: flex;
    }
  }

  .ant-drawer-body {
    padding-top: 1em;

    .Chat {
      .chat_header {
        display: none;
      }

      .chat_content {
        border: none;

        .chat_actions {
          padding: 1em !important;
        }
      }
    }

    .ChatListComponent {
      .create_chat_button {
        display: none;
      }
    }

    .chat_messages {
      padding: 1em 1em 1em 2em !important;
    }
  }
  .chat_title {
    .chat-label {
      max-width: 14rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
