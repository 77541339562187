.volume-progress {
  margin: 0;
  padding: 0;
}

.volume-container {
  height: 100%;
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 100;

  > .bar {
    width: 6px;
    border-radius: 6px;
    background: rgba(yellow, 0.65);
    transition-property: height background-color;
    transition-duration: 0.25s;

    &.level0 {
      height: 0;
      background-color: rgba(yellow, 0.65);
    }
    &.level1 {
      height: 10%;
      background-color: rgba(yellow, 0.65);
    }
    &.level2 {
      height: 20%;
      background-color: rgba(yellow, 0.65);
    }
    &.level3 {
      height: 30%;
      background-color: rgba(yellow, 0.65);
    }
    &.level4 {
      height: 40%;
      background-color: rgba(orange, 0.65);
    }
    &.level5 {
      height: 50%;
      background-color: rgba(orange, 0.65);
    }
    &.level6 {
      height: 60%;
      background-color: rgba(red, 0.65);
    }
    &.level7 {
      height: 70%;
      background-color: rgba(red, 0.65);
    }
    &.level8 {
      height: 80%;
      background-color: rgba(#000, 0.65);
    }
    &.level9 {
      height: 90%;
      background-color: rgba(#000, 0.65);
    }
    &.level10 {
      height: 100%;
      background-color: rgba(#000, 0.65);
    }
  }
}
