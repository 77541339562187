.Me {
  width: 16rem;
  height: 12rem;
  bottom: 1rem;
  left: 1rem;
  position: fixed;
  z-index: 99;

  &-share {
    width: auto;
  }
}
