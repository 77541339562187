@import '../../../assets/scss/core/variables';
.pending-meetings-table {
  .row-fully-voted {
    color: green;
  }

  .row-urgent {
    color: $errorColor;
  }
}

.status-column {
  max-width: unset !important;
  min-width: 4em !important;
}
