@import '../../assets/scss/core/variables';

.Faq {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .main-image {
      width: 25em;
    }

    .main-collapse {
      width: 50em;
    }
  }

  ul,
  ol {
    color: black;

    &.no-style-question {
      color: $textColor;
      list-style: none;
    }

    li {
      line-height: 1.4em;
    }
  }

  p {
    text-align: justify;
    font-size: inherit;
  }

  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
  }

  .ant-collapse-header-text {
    font-size: 1.1em;
    font-weight: bold;
  }

  .extra-text {
    font-weight: bold;
    margin-right: 0.5em;
  }

  .nav-icon {
    width: 2em;
  }
}

.faq-tooltip-image {
  width: 100%;
  max-width: 20rem;
}

.faq-tooltip-image-medium {
  width: 100%;
  max-width: 25rem;
}

.faq-tooltip-image-large {
  width: 100%;
  max-width: 30rem;
}

@media (max-width: $l) {
  .Faq {
    &-container {
      padding: 2rem 1rem;
    }

    .main-image {
      display: none;
    }

    .main-collapse {
      width: unset;
    }
  }
}
