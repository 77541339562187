@import '../../assets/scss/core/variables';

.upload-files-list {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 5px #333;
  width: 20rem;
  z-index: 99999;
  padding: 0;

  .ant-card-head {
    min-height: 0;
    padding: 0.5rem;
  }

  .ant-card-body {
    padding: 0.5rem;
    max-height: 25rem;
    overflow-y: auto;
  }

  .upload-file-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    &-progress {
      width: 100%;

      .file-name-container {
        display: flex;
        justify-content: space-between;

        .file-name {
          overflow: hidden;
          white-space: nowrap; /* Empêche le saut de ligne */
          text-overflow: ellipsis; /* Ajoute les points de suspension */
          max-width: 13rem;
        }

        .file-icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .upload-file-item-btn {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 1rem;
      color: $defaultTextColor;

      &:hover {
        background-color: $lightgrey;
      }
    }
  }

  .upload-file-item p {
    margin: 0;
  }
}
