@import '../../assets/scss/core/variables';

.Peers {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;

  .PeerView {
    .display-name {
      top: 0.5rem;
    }
  }
}

.PeersWithPromoted {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;

  .DecreasedPeers {
    overflow: hidden;
    position: relative;
    padding: 0.25rem 0.25rem 0 0.25rem;

    video {
      &.draggable {
        display: none;
        background-color: red;
      }
    }

    .DecreasedPeers-left,
    .DecreasedPeers-right {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      height: 100%;
      width: 4rem;
      z-index: 900;

      .chevron-container {
        cursor: pointer;
        border-radius: 50%;
        background-color: $primaryColorDarker;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .DecreasedPeers-left {
      left: 2rem;
    }

    .DecreasedPeers-right {
      right: 2rem;
    }

    .DecreasedPeers-scrollable {
      display: flex;
      width: fit-content;

      .Peer {
        flex-basis: 16rem;
        flex-grow: 0;
        flex-shrink: 0;
        width: 16rem;
        height: 10rem;
        padding: 0.125rem;

        .PeerView {
          &-initials {
            //initials for the reduced peers
            left: calc(50% - 2.5rem);
            top: calc(50% - 2.5rem);
            border-radius: 50%;
            width: 5rem;
            height: 5rem;
            line-height: 5rem;
            font-size: 2rem;
            z-index: 0;
          }

          .display-name {
            bottom: 1.5rem;
            right: 0.5rem;
          }
        }
      }
    }
  }

  .PromotedPeer {
    flex-grow: 1;
    flex-shrink: 1;
    z-index: 199;

    .Peer {
      border: none;

      .PeerView {
        .display-name {
          top: 0.5rem;
          left: 0.5rem;
        }
      }

      video,
      canvas {
        object-fit: contain;
        height: calc(100vh - 12rem);

        &.is-webcam-reduced {
          width: 12rem;
          height: 9rem;
        }
      }
    }

    &--Single {
      .Peer {
        video,
        canvas {
          height: 100vh;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .Peers {
    width: 100vw !important;
  }
}
