@import '../../assets/scss/core/variables';

.applications {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3em 14em 5em;
  overflow: hidden;

  .applications-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;

    &--mobile {
      display: none;
    }

    .row-container {
      width: 100%;

      .title {
        color: $primaryColor;
        font-size: 1.6em;
        margin-left: 0.7em;
        margin-bottom: 0.2em;
      }

      .row {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
        gap: 1.5em;
        justify-items: center;
        align-items: center;
      }
    }
  }
}

.upload-dragger .ant-upload.ant-upload-drag {
  padding: 0 !important;
  height: 10em;
}
.uploaded-image-preview-favorite {
  width: auto;
  max-height: 8em;
}

@media (max-width: $xl) {
  .applications {
    padding: 5em 9em;
  }
}

@media (max-width: $l) {
  .applications {
    padding: 3em 7em;
    height: unset;

    .empty-favicon {
      font-size: 4em !important;
    }
  }
}

@media (max-width: $sm) {
  .applications {
    padding: 1em;
    .applications-content {
      .row-container {
        .row {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
