@import '../../assets/scss/core/variables';

.DriveCard {
  .ant-card-body {
    padding: 0.5em !important;
    background-color: $extraLightgrey;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: baseline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      color: $defaultTextColor;
      font-weight: bold;
      margin-left: 0.3em;
      flex-grow: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }

    .actions-container {
      display: flex;
      align-items: center;
    }
  }
}
.child-folders-popover {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  max-height: 5em;
  overflow-y: auto;
}

.child-folder {
  display: flex;
  align-items: center;
  gap: 0.2em;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
