@import '../../assets/scss/core/variables';

.toolbar {
  &-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-mobile {
    display: none;
  }

  .change-date {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .calendar-navigation-group {
    height: 2em;

    .ant-btn {
      height: 100% !important;
      border-inline-start-color: transparent !important;
      border-inline-end-color: transparent !important;
    }
  }

  .select-meetings-type {
    .ant-select-selector {
      height: 2em !important;
    }
  }
}

@media (max-width: $md) {
  .toolbar {
    &-desktop {
      display: none;
    }

    &-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}

@media (max-width: $xs) {
  .toolbar {
    .change-date {
      flex-direction: column;
      margin-bottom: 1rem;
      gap: 0;
    }
  }
}

.calendar-opacity {
  opacity: 50%;
  pointer-events: none;
}

.rbc-event-label {
  display: none;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid;
  display: grid;
  overflow: hidden;
  position: absolute;
  align-items: unset !important;
}

.rbc-today {
  background-color: $extraLightgrey;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.rbc-timeslot-group {
  min-height: 5.7vh;
}

.colorPicker-calendar {
  border: 1px solid $secondaryColor;
}
