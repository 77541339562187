@import '../../assets/scss/core/variables';

.account-header {
  margin-top: 1.5rem;
}

.account-info-container {
  display: flex;
  margin: 0.5rem 0;

  .account-info-container-label {
    width: 10rem;
  }

  .account-info-container-divider {
    margin-right: 2rem;
  }
}

.select-language .ant-select-item.ant-select-item-option .ant-select-item-option-content {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

.account-modify-form {
  max-width: 40rem;

  .ant-row {
    margin: 0 !important;
    row-gap: 0 !important;
  }

  .ant-form-item {
    margin-bottom: 0.5rem;
  }
}

.layout-list-header {
  font-size: 1rem;
}

.account-notification {
  .ant-form-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-form-item-label {
      width: auto;
      left: 0;
    }

    .ant-form-item-control {
      width: auto;
      right: 0;
      flex: initial;
    }

    .ant-form-item-control-input {
      width: min-content;
    }
  }
}

@media (max-width: $md) {
  .account-modify-form {
    max-width: unset;
  }
}
