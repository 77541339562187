.chat_icon {
  text-transform: capitalize;
  font-size: 0.9em;
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  color: #ffffff;
  background-color: #224160;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  &--large {
    width: 2.4em !important;
    height: 2.4em !important;
    font-size: 1.2em;
    margin-top: 0.2em;
  }
}
