@import '../../../assets/scss/core/variables';

.chat_message_text a {
  text-decoration: none;
}

.chat_message_text a {
  &:hover {
    color: $textLinkColorHover;
    text-decoration: underline;
  }
}
