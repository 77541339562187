@import '../../assets/scss/core/variables';

.organisation-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.organisation-menu {
  display: flex;
  flex-direction: column;
  height: calc(100% - 5em);
  justify-content: space-between;
  overflow: hidden;

  .license {
    padding: 1rem;
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    .license-number {
      font-size: 1.3rem;
      font-weight: bold;

      &--red {
        color: $errorColor;
      }
    }
  }
}
