.ConnectionError {
  text-align: center;
  font-size: 1.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 10rem;
  }

  .wifi-bar {
    animation: blink-animation 1s steps(5, start) infinite;

    &:nth-child(3) {
      animation-delay: -0.5s;
    }

    &:nth-child(4) {
      animation-delay: -0.8s;
    }
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}
