@import '../../assets/scss/core/variables';

.calendar-menu {
  padding: 0 1em;

  .date {
    border-radius: 6px;
    border: 2px solid white;
  }

  .pending {
    color: white;
    background-color: $primaryColorLighter;
  }

  .meeting {
    color: white;
    background-color: $primaryColor;
  }

  .today {
    color: white;
    background-color: $secondaryColor;
  }

  .selected {
    border: 2px solid $secondaryColor;
  }
}

@media (max-width: $laptop) {
  .calendar-menu {
    .ant-picker-calendar-header {
      flex-direction: column;
      align-items: baseline;
      gap: 0.5rem;
    }
  }
}
