@import '../../../assets/scss/core/variables';

.DriveListContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  min-height: 100%;
  &-table-loading {
    opacity: 50%;
  }

  &-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    color: $defaultTextColor;

    &-header {
      display: flex;
      align-items: center;
      background-color: $extraLightgrey;
      font-weight: bold;
      border-bottom: 1px solid $grey;
      padding: 0.5em 0;
    }

    &-content {
      flex-grow: 1;
      cursor: pointer;
      position: relative;
      flex-shrink: 1;
      flex-basis: 10px;
    }

    &-row {
      display: flex;
      border-bottom: 1px solid $grey;
      height: 3rem;
      user-select: none;

      &-selected {
        background-color: $grey;
      }

      &:hover {
        background-color: $grey;
        .action-custom-container .action-hover-container {
          display: block !important;
        }
      }
    }

    &-name-column {
      display: flex;
      align-items: center;
      flex: 3;
      overflow: hidden;
      width: 100%;
      padding: 0 0.5em;

      .name {
        display: flex;
        align-items: center;
        gap: 0.5em;
        overflow: hidden;

        .document-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        .document-icon {
          display: flex;
          align-items: center;
          justify-content: start;
          width: 2em;
        }
      }
    }

    &-owner-column {
      display: flex;
      align-items: center;
      flex: 1.5;
    }

    &-date-column {
      display: flex;
      align-items: center;
      flex: 1.5;
    }

    &-size-column {
      display: flex;
      align-items: center;
      flex: 0.5;
    }

    &-actions-column {
      display: flex;
      align-items: center;
      justify-content: end;
      flex: 1;
      margin-top: 0.1rem;
    }
  }

  .file-dragging {
    opacity: 50%;
  }

  .folder-drag-over {
    border: 2px dashed $primaryColor;
    background-color: $primaryColorExtraLighter;
  }

  .loading-dropped-files {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
  }

  .ant-table-placeholder {
    display: none;
  }

  &-empty-container {
    height: 50vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    cursor: default !important;

    .empty-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      text-align: center;

      .name {
        max-width: 25em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-dragged {
      clear: both;
      opacity: 0.2;
      user-select: none;
    }

    &-drag-over {
      opacity: 0.7;
    }
  }

  .sort-icon-container {
    display: flex;
    flex-direction: column;

    .inactive {
      color: $sortArrowInactiveColor;

      &:hover {
        color: $defaultTextColor;
      }
    }

    .active {
      color: $secondaryColor;
    }
  }
}

.context-menu {
  .ant-menu-item {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}

.hidden {
  visibility: hidden;
}

.upload-banner-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  text-align: center;
  z-index: 1;
  transform: translate(-50%, -50%);

  .upload-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    .upload-icon {
      background-color: white;
    }

    &-text {
      background-color: white;
      border-radius: 1em;
      box-shadow: 2.5px 2.5px 10px $primaryColor;
      padding: 1em;
      color: $textColor !important;
    }
  }
}

.drag-preview {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  position: absolute;
  top: -1000px;
  border-radius: 8px;

  .file-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      max-width: 20em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }

  .badge {
    background-color: #224160;
    color: white;
    padding: 0.2rem 0.5rem;
    position: absolute;
    top: -1rem;
    right: -1rem;
    border-radius: 50%;
  }
}

@media (max-width: $laptop) {
  .DriveListContent {
    width: 100%;

    .name-row {
      width: 11em !important;
    }
  }
}

@media (max-width: $md) {
  .DriveListContent {
    width: 100%;

    .name-row {
      width: 100% !important;
    }
  }
}

@media (max-width: $sm) {
  .DriveListContent {
    width: 100%;

    &-table {
      &-header {
        display: none;
      }

      &-row {
        flex-direction: column;
        height: 11rem;
        border: solid 1px $lightgrey;
        padding: 0.5rem;
        gap: 0.5rem;
        margin-bottom: 1rem;
      }

      &-name-column {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-owner-column {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-date-column {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-size-column {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-actions-column {
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  }
}

.selected-row {
  background-color: #f2f2f2; /* Couleur de fond pour la sélection */
}
