.Homepage {
  .header {
    position: fixed;
    padding: 1rem;
    width: 100%;
    background-color: #d5ebff83;
    text-align: right;
    border-bottom: 1px solid #b2d9fd82;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 99;

    .login-link {
      > * {
        vertical-align: middle;
      }
    }
  }

  .presentation {
    background: #deefff;
    height: 100vh;
    min-height: 30rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      height: 12rem;
      max-width: 100%;
      margin: 2rem auto 2rem auto;
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }

  .liscense_pricing {
    margin-top: -5rem;

    .liscense_pricing_container {
      margin: 0 auto;
      width: 56rem;
      background: #ffdfdf;
      padding: 1rem 2rem 2rem 2rem;
      border-radius: 2rem 2rem 0 0;

      .liscense_pricing_title {
        font-size: 3rem;
        text-align: center;
      }

      .liscense_pricing_row {
        .liscense_pricing_card_proeminent {
          border: 1px solid #7cc0ff;
          background-color: #f3f9ff;

          .ant-card-head {
            border-bottom: 1px solid #7cc0ff;
          }

          .ant-card-actions {
            background: transparent;
            border-top: 1px solid #7cc0ff;
          }

          &:hover {
            border: 1px solid #7cc0ff;
            box-shadow:
              0 1px 2px -2px rgba(24, 144, 255, 0.26),
              0 3px 6px 0 rgba(24, 144, 255, 0.22),
              0 5px 12px 4px rgba(24, 144, 255, 0.29);
          }
        }

        .liscense_pricing_card_title {
          display: flex;
          justify-content: space-between;

          .liscense_pricing_card_title_text {
            font-size: 1.4rem;
          }

          .liscense_pricing_card_title_price {
            color: #777;
          }
        }
      }
    }
  }

  footer {
    margin-top: 5rem;
    border-top: 1px solid #aaaaaa;
    background-color: #ffffff;
    padding: 1rem;
  }
}
