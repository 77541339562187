@import '../../assets/scss/core/variables';

.Viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-fullSize {
    top: 0 !important;
  }

  &-control-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    padding: 1em;

    &-left {
      font-weight: 600;
      font-size: 1.1em;
      gap: 0.2em;

      .back {
        cursor: pointer;
      }
    }

    &-center {
      .title {
        padding-left: 0.5em;
        max-width: 25em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 0.2em;
      cursor: pointer;
      margin-right: 1em;
    }
  }

  &-wrapper {
    z-index: 999;
    width: 80%;
    height: 100%;
    margin-bottom: 1em;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    iframe {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: none;
    }

    video,
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border: none;
    }

    .unsupported {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 2em;
      gap: 1em;
      border-radius: 8px;
    }
  }

  &-wrapper-transparent {
    background: transparent !important;
  }
}

@media (max-width: $xs) {
  .Viewer {
    .title {
      max-width: 8rem;
    }
  }
}
