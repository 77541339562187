@import '../../../assets/scss/core/variables';

.manage-participant {
  .participant-help-text {
    color: $textColor;
  }

  .autocomplete-participant-circle {
    color: #47945e;
  }

  .select-role {
    width: 102.5%;
  }

  .participant-tag {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    width: 94%;
    padding: 0.22rem;
    padding-bottom: 0.35rem;
    background-color: $extraLightgrey;
  }

  .participant-info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item {
    p {
      margin-bottom: 0;
    }
  }
}

.label-container {
  display: flex;
  justify-content: space-between;
}

.select-items {
  margin-bottom: 0;
}
