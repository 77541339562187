@import '../../assets/scss/core/variables';

.ApplicationCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  transition: transform 0.4s ease-in-out;
  width: 9em;
  height: 10em;
  position: relative;

  &:hover {
    background-color: $darkGrey;
    border-radius: 8px;

    .dropdown-container {
      display: block;
    }
  }

  .dropdown-container {
    position: absolute;
    top: 1.45em;
    right: 1.6em;
    display: none;
  }

  .add {
    color: $secondaryColor;
  }

  .ant-card {
    padding: 0;
  }

  .card {
    width: 7em;
    height: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--activate {
      cursor: pointer;
      box-shadow:
        0 2px 0px rgba(0, 0, 0, 0.3),
        0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &--disabled {
      background-color: lighten($darkGrey, 20%);
      cursor: not-allowed;
      box-shadow: unset !important;

      .logo,
      .empty-favicon {
        opacity: 0.5;
      }
    }

    .logo-intern {
      width: 6em;
    }

    .logo-extern {
      width: 4em;
    }
  }

  .name {
    color: $primaryColor;
    margin-top: 0.6em;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 5em;
    margin-bottom: 0.5em;
    font-size: 1.1em;
  }

  .icon {
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }

  .empty-favicon {
    font-size: 4em;
    margin: 0;
  }

  .lock-overlay {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 0.8;

    .lock-icon {
      color: $darkGrey;
      font-size: 1.5em;
      margin: 0.1em;
    }
  }
}

@media (max-width: $sm) {
  .ApplicationCard {
    width: 20em;
    height: 11em;

    .card {
      width: 100%;
    }
  }
}

@media (max-width: $xs) {
  .ApplicationCard {
    width: 16em;
    height: 11em;

    .card {
      width: 100%;
    }
  }
}
