@import '../../assets/scss/core/variables';

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  header {
    background-color: #ffffff;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    height: 4rem;

    .page-burger-menu-container {
      display: none;
    }

    .general-burger-menu {
      display: none;
    }

    .header-left {
      display: flex;
      align-items: center;
      padding-left: 0.8rem;
      width: 16vw;
      height: 4rem;
      background-color: $extraLightgrey !important;
      border-right: solid 1px $lightgrey;

      &--light {
        background-color: white !important;
        border-right: unset !important;
      }

      .logo {
        height: 2.5rem;
        display: flex;
        align-items: center;

        img {
          &.logo-image {
            height: 100%;
            margin-right: 0.5rem;
          }

          &.logo-image-small {
            display: none;
            height: 3rem;
            margin-right: 0.5rem;
          }
        }
      }
    }

    .header-right-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 84vw;
      height: 4rem;

      &--bordered {
        border-bottom: solid 1px $lightgrey;
      }

      .title {
        margin-bottom: 0 !important;
        margin-left: 1rem;
      }

      .search-header-container {
        width: 30em;
      }

      .header-right {
        display: flex;
        align-items: center;
        gap: 2rem;
        padding-right: 0.8rem;

        .avatar {
          background-color: $primaryColor;
          padding: 1.3em;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          cursor: pointer;

          &:hover {
            background-color: $primaryColorLighter;
          }
        }

        .title-header {
          line-height: normal;
          .name-legend {
            &:hover {
              text-decoration: underline;
            }
          }

          .title-header-legend {
            font-size: smaller;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  main {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-grow: 1;

      &--primary {
        background-color: $primaryColor;
      }
      aside {
        flex-grow: 0;
        flex-shrink: 0;
        width: 16vw;
        border-right: solid 1px $lightgrey;
        padding: 1em 0 1em 0;
      }

      .aside-bg {
        background-color: $extraLightgrey !important;
      }

      .article {
        flex-grow: 1;
        flex-shrink: 1;
        width: 84vw;
        padding: 1.5em 2em 0 2em;
        border-left: solid 1px white;
        overflow-y: auto;

        &--no-border {
          border-left: none !important;
        }

        &--no-padding {
          padding: 0 0 1rem 0;
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  .background {
    background-color: $extraLightgrey;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0.5rem 0.5rem 0.5rem;

    &--primary {
      color: white;
      background-color: $primaryColor;

      a {
        color: white;
      }
    }
  }
}

.hideLargeScreen {
  display: none;
}

@media (min-width: $md) and (max-width: $lg) {
  .hideLargeScreen {
    display: block;
  }
}

@media (max-width: $laptop) {
  .layout {
    .header-left {
      width: 25vw !important;
    }

    .header-right-container {
      width: 75vw !important;
    }

    .search-header-container {
      width: 20em !important;
    }

    aside {
      width: 25vw !important;
    }

    article {
      width: 75vw !important;
    }
  }
}

@media (max-width: $lg) {
  .layout {
    header {
      padding: 0.5em 1em !important;
      border-bottom: solid 1px $lightgrey;

      .header-left {
        background-color: white !important;
        border-right: unset;
        height: unset;
      }

      .search-header-container {
        display: none;
      }

      .header-right-container {
        justify-content: end;
        width: unset !important;
        border-bottom: unset;

        .title {
          display: none;
        }

        .header-right {
          width: 10em;
          display: flex;
          justify-content: end;
          gap: 1em;

          .title-header {
            display: none !important;
          }
        }
      }

      .page-burger-menu-container {
        width: 10em;
        display: flex;
        justify-content: start;
      }
    }

    aside {
      display: none !important;
    }

    article {
      width: 100vw !important;
    }
  }
}

@media (max-width: $sm) {
  .layout {
    header {
      .general-burger-menu {
        display: block;
      }

      .page-burger-menu-container {
        display: none;
      }

      .header-right {
        display: none;
      }

      .header-left {
        .logo {
          height: 2rem;

          img.logo-image {
            display: none;
          }

          img.logo-image-small {
            display: inline;
            height: 2.5rem;
          }
        }
      }
    }

    footer {
      display: none !important;
    }
  }
}

@media (max-width: $sm) {
  .layout main article {
    padding: 0;
  }
}
