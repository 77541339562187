@import '../../../assets/scss/core/variables';
.ant-table {
  .ant-table-tbody .ant-table-measure-row td {
    padding: 0 !important;
  }

  th.ant-table-cell {
    filter: none;
  }
}

.choose-vote {
  span {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }

  &.myvote span {
    cursor: pointer;
  }
}

.row-voted {
  color: green;
}

.row-not-voted {
  color: $errorColor;
}

.meeting-vote-list ul {
  max-height: 14rem;
  overflow-y: auto;
}

@media screen and (max-width: $sm) {
  .hide-on-mobile {
    display: none !important;
  }

  .meeting-vote-table-container .ant-table-wrapper .ant-table .ant-table-cell {
    padding: 0 !important;
  }

  .ant-table-content table {
    max-width: 100% !important;

    colgroup {
      display: none;
    }

    & .ant-table-tbody .ant-table-measure-row {
      display: none !important;
    }
  }
}

.vote-content {
  flex-grow: 1;
}

.gap-header {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.thanks-vote-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-authorized-message {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
