@import '../../assets/scss/core/variables';

.country-flag {
  height: 1em;
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.language-icon {
  font-size: 0.9rem;
  cursor: pointer;
  align-items: center;

  .language-text {
    margin-left: 0.5em;
    color: $textColor;
    font-size: 0.8rem;

    svg {
      margin-left: 0.2em;
    }

    &--light {
      color: white !important;
    }
  }
}
