@import '../../assets/scss/core/variables';

.Auth {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;

  .documents-banner {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: end;
    align-items: center;
    color: $textColor;
    text-align: center;
    z-index: 1;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .layer {
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo {
    width: 16rem;
    max-width: 100%;
    z-index: 999;
  }

  .logo-container {
    z-index: 999;
    text-align: center;
  }

  .secure-link {
    text-decoration: underline;
    color: inherit;
  }

  .secure-link:hover {
    text-decoration: none;
  }

  .auth-methods {
    display: flex;
    margin: 3rem auto 1rem auto;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 1rem;
    z-index: 999;
    position: relative;

    .auth-methods-login-password {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 2rem;
      width: 22rem;

      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .login-label {
        color: $textColor;
      }

      .ant-form-item-label > label {
        font-weight: bold;
      }

      .auth-misc-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
      }
    }

    .auth-methods-separator {
      border-left: 1px solid $lightgrey;
      margin: 2rem 2rem;
    }

    .auth-methods-secure_and_access-phone {
      display: none;
      margin: 1rem 0 0 0;
    }

    .auth-methods-secure_and_access {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 1.5rem 0 1rem;
      gap: 0.5rem;

      img {
        width: 12rem;
        margin: 0 0 0.4rem 0;
      }
    }

    .qr-code {
      width: 15em;
      font-size: 0.8em;
      .qr-code-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 19.5em;
      }
    }

    .refresh-methods-secure_and_access-button {
      margin: 0.5rem 0 0.5rem 0;
      width: 100%;
    }
    .auth-methods-devs {
      display: flex;
      justify-content: end;
      .ant-select {
        width: 150px;
      }
    }

    .auth-methods-language {
      position: absolute;
      left: -0.5rem;
      top: 1rem;
      display: flex;
      justify-content: end;
      width: 100%;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-affix-wrapper {
    padding: 0.7em; //like btn
    font-size: 1em;
  }

  .ant-btn-text {
    font-size: 0.7rem;
  }

  .secure-text {
    line-height: 1.5;
    text-align: center;
    margin: 0;
    width: 12rem;
    color: $textColor;
  }
}

.reinit-password-modal-footer {
  display: flex;
  justify-content: space-between;
}

.signup-modal-footer {
  display: flex;
  justify-content: space-between;
}

/*** Responsive ***/

@media (min-width: $md) and (max-width: $lg), (max-height: $md) {
  .Auth {
    .ant-input-affix-wrapper {
      padding: 0.5em; //like btn
      font-size: 1em;
    }

    .auth-methods {
      padding: 0rem !important;
    }

    .auth-methods-separator {
      margin: 2rem 0 !important;
    }

    .logo {
      width: 12rem !important;
    }

    .auth-methods-secure_and_access {
      padding-right: 0.5rem !important;

      img {
        width: 10rem !important;
        margin: 0 !important;
      }
    }
    .auth-methods-language {
      left: -1rem !important;
    }

    .auth-methods-login-password {
      padding: 1.5rem !important;
    }

    .secure-text {
      line-height: 1.2 !important;
    }
  }
}

@media (max-width: $md) {
  .Auth {
    .logo {
      margin-bottom: 0.5rem;
      width: 10rem !important;
    }

    .auth-methods {
      flex-direction: column;
      padding: 0rem !important;
      margin: 1rem auto 1rem auto !important;
    }

    .auth-methods-login-password {
      width: 100%;
      padding: 1rem !important;
      width: 18rem !important;
    }

    .auth-methods-separator {
      display: none;
    }

    .auth-methods-secure_and_access {
      padding: 0 2rem 1rem 2rem !important;
    }
  }
}

@media (max-width: $md) {
  .Auth {
    .auth-methods-secure_and_access {
      display: none !important;
    }

    .auth-methods-login-password {
      width: 25rem !important;
    }

    .documents-banner {
      justify-content: center;
      margin-bottom: 0.5rem;
    }

    .auth-methods-secure_and_access-phone {
      display: block !important;
    }

    .secure-text {
      width: 100%;
      margin: 0 0 0.3rem 0;
    }
  }
}

@media (max-width: $sm) {
  .Auth {
    .auth-methods-login-password {
      width: 20rem !important;
    }

    .documents-banner {
      flex-direction: column;
      text-align: center;

      .badges-container {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}

@media (max-height: $sm) and (max-width: $md) {
  .Auth {
    .auth-methods {
      margin: 1rem auto 1rem auto !important;
    }

    .auth-methods-secure_and_access {
      display: none !important;
    }
  }
}

@media (max-height: $xs) {
  .Auth {
    .logo {
      margin-bottom: 1rem;
    }

    .auth-methods {
      margin: 1rem auto 1rem auto !important;
    }

    .auth-methods-login-password {
      padding: 1rem !important;
    }

    .auth-methods-secure_and_access {
      padding: 0 1rem !important;
    }

    .auth-methods-login-password {
      gap: 0.5rem !important;
    }
  }
}
