@import '../../assets/scss/core/variables';

.uploaded-image-container {
  margin-bottom: 24px;
  margin-top: 24px;
}

.uploaded-image-preview {
  margin: 0.5rem 0;
  background-image: url('./assets/background-transparent.png');
  border: 1px solid #e1e1e1;
}

.uploaded-image-success {
  position: absolute;
  top: -1.5rem;
  left: 0rem;
}

.uploaded-image-preview-login-logo {
  width: 12rem;
}

.uploaded-image-preview-login-background {
  width: 15rem;
  height: 9rem;
  background-image: url('/../public/images/#{$backgroundImageFilename}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.uploaded-image-preview-header-logo {
  height: 2.4rem;
}

.uploaded-image-preview-header-logo-mobile {
  height: 2.5rem;
}

.uploaded-image-preview-call-logo {
  height: 2.5rem;
}

.uploaded-image-preview-icon {
  width: 3rem;
}

.hr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.personnalisation-form-label {
  display: block;
  font-size: 14px;
  height: 32px;
  color: $textColor !important;

  &::after {
    display: inline-block;
    margin-inline-start: 0rem;
    color: #1591ff;
    font-size: 0.7rem;
    padding-bottom: 0.4rem;
    line-height: 1;
    content: '*';
    margin-left: 3px;
  }
}

.ant-color-picker-input-container {
  display: flex;
  align-items: center;
}

.input-with-help {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .ant-form-item {
    margin: 0;
  }

  .color-picker-restore {
    margin-left: 0.4rem;
    cursor: pointer;
  }

  .input-help {
    margin-left: 1rem;
  }
}

.fixed-custom-button {
  background: red;
  box-shadow: 3px 3px 6px rgb(0 0 0 / 84%);
  position: fixed;
  left: 2rem;
  top: 2rem;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.personnalization-ribon {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 3rem;
  background: red;
  top: 2.5rem;
  right: -3.5rem;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  transform: rotate(45deg);
  z-index: 999;
}

.custom-image-container {
  max-width: 10rem;
  max-height: 6rem;
  margin: 0.5rem 0;
}

.dragger .ant-upload.ant-upload-drag {
  background-color: $lightgrey;
  padding: 0 !important;
}

.ant-upload-hint {
  margin-bottom: 0;
}

.align-left {
  text-align: left;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0.5rem 0;
}

.build-in-progress-alert {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.submit-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
}
