@import '../../../assets/scss/core/variables';

.DriveForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .drive-name {
    margin-bottom: 0;
  }

  .participants-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .DriveFormAddParticipant {
    margin-bottom: 1rem;

    .label {
      margin-bottom: 0.2rem;
    }

    .role {
      .ant-select-selector {
        border-radius: 0;
      }
    }

    .add {
      .ant-btn {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none !important;

        .ant-btn-icon {
          margin-right: 0;
        }
      }
      .ant-btn:not(.ant-btn-text).ant-btn-sm {
        padding: 0.25em 0.5em !important;
      }
    }
  }
}

.delete-btn-containter {
  flex: 1;
  .delete-btn {
    margin-inline-start: 0 !important;
  }
}

@media (max-width: $sm) {
  .ant-modal-footer {
    .ant-btn {
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }
}
