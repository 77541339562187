@import '~antd/dist/reset.css';
@import './assets/scss/core/variables';
@import './assets/scss/core/fonts';
@import './antd_overwrite.scss';

* {
  font-family: 'Source Sans Pro', sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $textColor;
}

h4 {
  font-size: $defaultFontSize * 1.08 !important;
}

h4.uppercase {
  text-transform: uppercase;
  color: $secondaryColor;
  font-weight: 600;
}

a {
  color: $secondaryColor;
  text-decoration: none;

  &:hover {
    color: $secondaryColorLighten;
    cursor: pointer;
  }
}

p {
  font-size: $defaultFontSize;
  color: black;
}

.pointer-events-none {
  pointer-events: none;
}

.black-color {
  color: black !important;
}

.success-color {
  color: $successColor;
}

.hover-success-color:hover {
  color: $successColor;
}

.error-color {
  color: $errorColor;
}

.hover-error-color:hover {
  color: $errorColor !important;
}

.warning-color {
  color: $warningColor;
}

.hover-warning-color:hover {
  color: $warningColor;
}

.hover-text-bold:hover {
  font-weight: bold;
}

.fw-bold {
  font-weight: bold;
}

.mt-m1rem {
  margin-top: -1rem;
}

.mt-0rem {
  margin-top: 0rem;
}

.mt-0_2rem {
  margin-top: 0.2rem;
}

.mt-05rem {
  margin-top: 0.5rem;
}

.mt-1rem {
  margin-top: 1rem;
}

.mt-1_5rem {
  margin-top: 1.5rem;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.mr-05rem {
  margin-right: 0.5rem;
}

.mr-1rem {
  margin-right: 1rem;
}

.mr-2rem {
  margin-right: 2rem;
}

.mr-3rem {
  margin-right: 3rem;
}

.mb-02em {
  margin-bottom: 0.2em;
}

.mb-05rem {
  margin-bottom: 0.5rem;
}

.mb-0_1rem {
  margin-bottom: 0.1rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.ml-04rem {
  margin-left: 0.4rem;
}

.ml-05rem {
  margin-left: 0.5rem;
}

.ml-05em {
  margin-left: 0.5em;
}

.mr-05em {
  margin-right: 0.5em;
}

.ml-1rem {
  margin-left: 1rem;
}

.ml-1_2rem {
  margin-left: 1.2rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.ml-3rem {
  margin-left: 3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.m-0 {
  margin: 0 !important;
}

.m-0_5rem {
  margin: 0.5rem !important;
}

.mt-0_5rem {
  margin-top: 0.5rem !important;
}

.mb-0_5rem {
  margin-bottom: 0.5rem !important;
}

.m-1em {
  margin: 1em;
}

.mb-1em {
  margin-bottom: 1em;
}

.mb-2em {
  margin-bottom: 2em;
}

.p-0 {
  padding: 0;
}

.p-1rem {
  padding: 1rem;
}

.pb-5rem {
  padding-bottom: 5rem;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pb-0_5rem {
  padding-bottom: 0.5rem;
}

.pt-0_5rem {
  padding-top: 0.5rem;
}

.pt-1rem {
  padding-top: 1rem;
}

.p-05rem {
  padding: 0.5rem;
}

.p-0 {
  padding: 0 !important;
}

.pr-05rem {
  padding-right: 0.5rem;
}

.mw-35rem {
  max-width: 35rem;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100% !important;
}

.h-3rem {
  height: 3rem;
}

.h-1_8rem {
  height: 1.8rem;
}

.w-2rem {
  width: 2rem;
}

.w-5rem {
  width: 5rem;
}

.w-7rem {
  width: 7rem;
}

.w-13rem {
  width: 13rem;
}

.w-18rem {
  width: 18rem;
}

.w-min {
  width: min-content;
}

.w-auto {
  width: auto;
}

.g-1rem {
  gap: 1rem;
}

.mw-11rem {
  max-width: 11rem;
}

.mh-12rem {
  max-height: 12rem;
}

.va-middle {
  vertical-align: middle;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-color {
  color: $textColor;
}

.text-color-lighten {
  color: $textColorLighten;
}

.text-secondary-color {
  color: $secondaryColor;
}

.default-text-color {
  color: $defaultTextColor;
}

.text-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-scroll {
  overflow: auto;
  overflow-wrap: break-word;
}

.uppercase {
  text-transform: uppercase;
}

.dropdown-icon-margin-left {
  margin-left: calc(0.5em + 4px); // count padding
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.h-30rem {
  height: 30rem;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.d-flex-middle {
  align-items: center;
}

.d-flex-baseline {
  align-items: baseline;
}

.d-flex-start {
  align-items: flex-start;
}

.d-flex-center {
  justify-content: center;
}

.d-flex-right {
  justify-content: flex-end;
}

.justify-content-end {
  justify-content: end;
}

.d-flex-between {
  justify-content: space-between;
}

.d-flex-bottom {
  align-items: center;
}

.d-flex-end {
  align-items: end;
}

.d-flex-column {
  flex-direction: column;
}

.d-flex-row {
  flex-direction: row;
}

.d-flex-reverse {
  flex-direction: row-reverse;
}

.d-flex-inherit {
  flex: inherit;
}

.d-flex-auto {
  flex: auto;
}

.d-flex-grow-1 {
  flex-grow: 1;
}

.d-flex-3rem {
  flex: 3rem;
}

.d-none {
  display: none;
}

.z-index--1 {
  z-index: -1;
}

.g-0_2rem {
  gap: 0.2rem;
}

.g-0_5rem {
  gap: 0.5rem;
}

.g-0_1rem {
  gap: 0.1rem;
}

.g-2rem {
  gap: 2rem;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  word-break: break-all;
  display: flex;
  align-items: center;

  svg:nth-child(1) {
    margin-right: 0.5rem;
  }
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.link {
  display: flex;
  align-items: center;
}

tr > th:nth-last-child(-n + 1) {
  border-right: unset;
}

.td-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-1_35 {
  height: 1.35rem;
}

.hideOnMobile {
  display: none !important;
}

.hideOnSmallMobile {
  display: none !important;
}

.hideOnDesktop {
  display: block !important;
}

@media (max-width: $laptop) {
  html {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }
}

@media (max-width: $laptop) {
  .hideOnLaptop {
    display: none !important;
  }
}

@media (max-width: $laptop) {
  .showOnLaptop {
    // display: none !important;

    &.date-column {
      min-width: 2em !important;
    }
  }
}

.styled-icon {
  fill: $primaryColor !important;
  stroke: $primaryColor !important;
}

.nowrap {
  white-space: nowrap;
}

.btn-danger {
  border: none !important;
  background-color: $errorColor !important;
  color: white !important;
  &:hover {
    background-color: lighten($errorColor, 10%) !important;
  }
}

.btn-danger-border {
  border: solid 1px $errorColor !important;

  &:hover {
    background-color: $errorColorLighten !important;
  }
}

.large-modal {
  width: 50rem !important;
}

@media (min-width: $md) {
  .hideOnMobile {
    display: block !important;
  }

  .hideOnDesktop {
    display: none !important;
  }
}

@media (max-width: $md) {
  h1 {
    font-size: 1.4em !important;
  }
}

.layout-header {
  display: flex;
  flex-direction: column;
}

.layout-header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 2rem;
  font-size: 1rem;
  line-height: 1.15;
}

h1 {
  font-weight: bold;
  font-size: 1.6em;
}

h2 {
  font-size: 1.4em;
}

h2,
h4 {
  margin-bottom: 0;
}

.italic {
  font-style: italic;
}

.display-none {
  display: none !important;
}

.full-width-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-background {
  background: none !important;
}

.name-column {
  min-width: 10em;
}

.type-meeting-column {
  min-width: 0rem !important;
}

.details-column {
  width: 100%;
}

.types-column {
  max-width: unset !important;
  min-width: 18em !important;
}

.peer-column {
  max-width: unset !important;
  min-width: 18em !important;
}

.date-column {
  max-width: unset !important;
  min-width: 11em !important;

  &.ant-table-column-sort {
    background: none !important ;
  }
  &.ant-table-cell-row-hover {
    background: #fafafa !important;
  }
}

.duration-column {
  min-width: 4rem !important;
}

.creator-column {
  min-width: 9.5rem;
}

.create_dt-column {
  min-width: 9rem;
}

.full-name-column {
  min-width: 2rem;
}

.action-column {
  width: 11em;
  max-width: unset !important;
}

.action-main-container {
  min-width: 10em !important;
  display: flex;
  justify-content: end;
  height: 2.5em;
}

@media (max-width: $laptop) {
  .action-column {
    width: 4em !important;
  }
  .action-main-container {
    width: 4.5em !important;
  }
}

.action-custom-container .action-hover-container {
  display: none !important;
}

tr:hover .action-custom-container .action-hover-container {
  display: block !important;
}

.action-icons {
  display: flex;
  align-items: center;
  justify-content: end;
  color: $defaultTextColor;
}

.action-more-container {
  .action-more {
    cursor: pointer;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $defaultTextColor;

    &:hover {
      background-color: $lightgrey;
    }
  }
}

.show-timezone-container {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.2rem;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: -2rem;

  .show-timezone-txt {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    margin-left: 0.2rem;
  }
}

.vote-schedule {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  font-style: italic;
}

th.ant-table-cell {
  filter: opacity(80%);
  background-color: mix($textColor, $extraLightgrey, 5%) !important;
}

footer span.footer-document,
a.footer-document {
  cursor: pointer;
  text-decoration: underline;
}

a.footer-document {
  color: $textColor;
}

.info-color {
  color: $secondaryColor;
}

label {
  color: $textColor;
}

.underline {
  text-decoration: underline;
}

.hover-remove-underline {
  &:hover {
    text-decoration: none;
  }
}

.action-rounded-icon {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &--red {
    color: $errorColor;

    &:hover {
      background-color: $errorColorLighten !important;
      color: $errorColor !important;
    }
  }

  &:hover {
    background-color: $lightgrey;
    color: $textColor;
  }
}

.search-row {
  margin-bottom: 1rem;

  .label {
    margin-bottom: 0.2rem;
  }

  .ant-form-item,
  .ant-row,
  .ant-form-item-row,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .ant-select {
    height: 100% !important;
    max-height: 2.5em;
  }

  .ant-select-selector {
    width: unset !important;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .input-group {
    .ant-select-selector {
      height: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .role-selector {
    .ant-select-selector {
      border-radius: 0;
    }
  }

  .action-button {
    .ant-btn {
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none !important;

      .ant-btn-icon {
        margin-right: 0;
      }
    }
    .ant-btn:not(.ant-btn-text).ant-btn-sm {
      padding: 0.25em 0.5em !important;
    }
  }
}

@media (max-width: $l) {
  .creator-column,
  .create_dt-column {
    min-width: unset;
  }
}

@media screen and (max-width: $sm) {
  table {
    table-layout: fixed !important;
  }

  .date-column,
  .action-container {
    width: 100%;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.badges-container {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;

  .notarisation-container,
  .sovereignty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    cursor: pointer;

    img {
      width: 3rem;
    }
  }
}

.span-btn {
  padding: 0.35em 0.8em;
  color: $secondaryColor;
  border: 1px solid $secondaryColor;
  border-radius: 6px;
}

.file-icon {
  width: 1.5em;
  height: 1.5em;
}

.text-color-default {
  color: $defaultTextColor;
}

.white {
  color: white;
}

.delete-btn {
  &:hover {
    background-color: $errorColorLighten !important;
  }
}

.drawer-menu-responsive-mobile,
.drawer-menu-responsive-button {
  display: none;
}

@media (max-width: $sm) {
  .drawer-menu-responsive-mobile {
    display: flex;
    margin-right: 0.5rem;
  }
}

@media (max-width: $sm) {
  .drawer-menu-responsive-button {
    display: flex;
    margin-right: 0.5rem;
    height: 3.4em;
    position: absolute;
    top: 0.4em;
    left: 0.9em;
  }
}

.menu-action-btn-container {
  margin: 0 1rem 1rem 1rem;

  .menu-action-btn {
    width: 100%;
    font-weight: bold;
  }
}

@media (max-width: $lg) {
  .menu-action-btn-container {
    margin: 1rem;
  }
}

.ql-editor {
  font-size: 1.2em;
}

.primary {
  color: $primaryColor !important;
}

.mh-1em {
  margin-left: 1em;
  margin-right: 1em;
}

.modal-content-container {
  padding: 1em !important;
  gap: 1em;
  display: flex;
  flex-direction: column;
}

.virtual-list-participant {
  display: flex !important;
  flex-direction: column;
  max-height: 15rem;
  overflow-y: auto;
  padding-right: 1em;
  padding-left: 1em;

  .ant-list-item {
    padding: 0.4rem 0;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0 !important;
    overflow: hidden;
    word-wrap: normal;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }

  .ant-list-item-meta-description {
    overflow: hidden;
    word-wrap: normal;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }

  .ant-select-selector {
    min-width: 10rem;
  }
}

.participants-list {
  max-height: 25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  & > * {
    flex: 1;
  }

  .ant-card-head {
    min-height: 3em;
  }

  .ant-card-head,
  .ant-card-bordered {
    border-color: $antdGreyDefault;
  }
  .ant-card-body {
    padding: 1em;
  }
}

@media (max-width: $md) {
  .participants-list {
    flex-direction: column !important;
    gap: 0 !important;

    .divider-list {
      display: flex !important;
    }
  }
}

.chat-modal {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
