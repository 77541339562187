@import '../../../assets/scss/core/variables';

.GroupsList {
  .groups-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-collapse-header {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

@media (max-width: $md) {
  .GroupsList {
    .user-count {
      text-align: center;
      width: 100% !important;
    }
  }
}
