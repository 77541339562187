.DrivesHome {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  .search-container {
    width: 50%;
  }

  .drives-container {
    width: 100%;
  }
}
