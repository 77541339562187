@import './variables.scss';
@import '../../../assets/scss/core/mixins.scss';
@import '../../../assets/scss/core/variables';

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
}

.Chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .loading_new_chunk {
    position: fixed;
    z-index: 2;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    // backdrop-filter: blur(1px);
  }

  .chat_header {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    background-color: $extraLightgrey;
    padding: 0 0.8em;
    height: 3.6em;
    box-sizing: content-box;

    .ant-avatar {
      background-color: #ffffff;
    }

    .chat_header_title {
      margin-left: 1em;
    }

    .chat_header_separator {
      flex-grow: 100;
    }
  }

  .chat_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 10px;
    border-top: 1px solid #ffffff;

    .chat_scroll {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 10px;
    }

    .chat_actions {
      z-index: 3;
      padding: 1em 13em;
      position: relative;
      background-color: #fff;
      .text-area-editor {
        flex-grow: 1;
        background-color: #fff;
      }

      .ql-container {
        border: none;
        max-height: 15rem;
        resize: vertical;
        overflow-y: auto;
        border-radius: 8px;

        .ql-editor {
          padding-bottom: 0;
        }
      }
    }

    .scroll-to-bottom {
      position: absolute;
      top: -2em;
      right: 1em;
      cursor: pointer;

      opacity: 0;
      animation-duration: 0.5s;
      animation-name: slide-out;
      pointer-events: none;

      &--shown {
        pointer-events: all;
        opacity: 1;
        animation-duration: 0.5s;
        animation-name: slide-in;
        animation-fill-mode: backwards;
      }
    }
  }
}

.peers_list {
  max-height: 15rem;
  overflow-y: auto;
  margin-bottom: 1.7em;

  .peers_list_peer_username {
    flex-grow: 1;
  }
}

.add_peer_input {
  flex-grow: 1;
  margin-top: 0 !important;
}

@media (max-width: $xxl) {
  .chat_actions {
    padding: 1em 10em !important;
  }
}

@media (max-width: $laptop) {
  .chat_actions {
    padding: 1em 3em !important;
  }
}

@media (max-width: $md) {
  .chat_actions {
    padding: 1em 2em !important;
  }
}
