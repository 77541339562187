@import '../../../assets/scss/core/variables';

.emoji_picker_container {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .emoji_picker {
    .emoji_picker_modal {
      width: auto;
      height: 2rem;
      padding: 0;
      --epr-emoji-size: 15px;
    }

    .quick_reaction_container {
      display: flex;
      gap: 0.3rem;
      padding: 0.1rem;
      padding-right: 0.6rem;
      padding-left: 0.6rem;
      border-radius: 2rem;
      background-color: $extraLightgrey;
      border: 1px solid $darkGrey;

      .quick_reaction_item {
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: $grey;
        }

        .emoji-mart-emoji {
          span {
            font-size: 1.2em !important;
          }
        }
      }

      .quick_reaction_item--emoji {
        > * {
          transition: all 0.2s;
        }

        &:hover > * {
          transform: scale(1.2);
        }
      }

      .quick_reaction_item {
        cursor: pointer;

        svg {
          width: 1.3em;
        }
      }
    }
  }
}
